import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from 'utils/colors';
import CountUp from 'react-countup';
import { boxShadow } from 'styles/Styles';

export const CountingBox = ({ start, end, delay, description, darkMode }) => (
  <StyledCountingBox darkMode={darkMode}>
    <CountUp start={start} end={end} delay={delay}>
      {({ countUpRef }) => <StyledCountingBoxNumber ref={countUpRef} />}
    </CountUp>
    <StyledCountingBoxDescription>{description}</StyledCountingBoxDescription>
  </StyledCountingBox>
);

CountingBox.propTypes = {
  start: PropTypes.number,
  end: PropTypes.number.isRequired,
  delay: PropTypes.number,
  description: PropTypes.string.isRequired,
  darkMode: PropTypes.bool,
};

CountingBox.defaultProps = {
  start: 0,
  delay: 0,
  darkMode: false,
};

const StyledCountingBox = styled.div`
  text-align: center;
  background: ${colors.lightBackground};
  padding: 3rem 2rem;
  margin-left: 2rem;
  margin-right: 2rem;
  box-shadow: ${({ darkMode }) => (darkMode ? boxShadow.dark : boxShadow.light)};
`;

const StyledCountingBoxNumber = styled.h3`
  font-size: 1.5rem;
  color: ${colors.black};
  text-transform: capitalize;
  &:after {
    content: '+';
  }
`;

const StyledCountingBoxDescription = styled.p`
  font-size: 1.5rem;
  color: ${colors.lightColor};
  line-height: 1.5;
  padding-top: 0.5rem;
`;
