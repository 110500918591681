export const colors = {
  red: '#E31F25',
  black: '#111',
  white: '#fff',
  lightColor: '#666',
  lightBackground: '#eee',
  boxShadow: '0 .5rem 1rem rgba(0,0,0,.1)',
  border: '.1rem solid rgba(0,0,0,.3)',
  lightGrey: '#e8eaea',
  lightGrey2: '#F4F7FA',
  purple: '#324367',
};
