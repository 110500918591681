import styled from 'styled-components';
import { colors } from 'utils/colors';
import { maxDeviceSize } from 'utils/deviceSize';

const HeaderLayout = styled.section`
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: ${colors.boxShadow};
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
  width: 100%;
  padding: 0.5rem calc((100vw - 1500px) / 2);
  background: ${({ theme }) => theme.background};
  @media ${maxDeviceSize.tablet} {
    padding: 2rem calc((100vw - 1500px) / 2);
    padding: 1.5rem;
  }
`;

export default HeaderLayout;
