import React from 'react';
import { styled } from 'styled-components';
import { colors } from 'utils/colors';
import { maxDeviceSize } from 'utils/deviceSize';
import RoofImage from 'assets/images/dach.jpg';

const Home = () => (
  <section name="about">
    <StyledHomeContainer>
      <StyledTitle id="main-title">Fachowe i kompleksowe pokrycia dachowe</StyledTitle>
      <StyledHomeImageBackground src={RoofImage} alt="Tło dachu" />
    </StyledHomeContainer>
  </section>
);

const StyledHomeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
`;

const StyledTitle = styled.h1`
  position: absolute;
  top: 9%;
  z-index: 1000;
  font-size: 3rem;
  color: ${colors.black};
  text-transform: capitalize;
  font-weight: 200;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 2rem;

  @media ${maxDeviceSize.tablet} {
    font-size: 1rem;
    color: ${colors.black};
    text-transform: capitalize;
    top: 3.5%;
    text-align: center;
    font-weight: 300;
    width: 80%;
  }
`;

const StyledHomeImageBackground = styled.img`
  height: 677px;
  width: 100%;
  position: absolute;
  top: 0;
  opacity: 0.7;
  overflow: hidden;
  z-index: 800;
  @media ${maxDeviceSize.tablet} {
    height: 337px;
    margin-top: 50px;
  }
`;

export default Home;
