import React from 'react';
import SectionLayout from 'layouts/SectionLayout';
import PromoAboutVideo from 'assets/videos/promo-video.mp4';
import { CountingBox } from 'components/atoms/CountingBox/CountingBox';
import styled from 'styled-components';
import { maxDeviceSize } from 'utils/deviceSize';
import SectionTitle from 'components/atoms/SectionTitle/SectionTitle';
import PropTypes from 'prop-types';

const About = ({ darkMode }) => (
  <StyledSectionWrapper>
    <SectionLayout name="about" darkMode={darkMode}>
      <SectionTitle title="O nas" />
      <StyledAboutContainer>
        <StyledVideoContainer>
          <StyledVideo src={PromoAboutVideo} loop muted autoPlay />
        </StyledVideoContainer>
        <StyledContentContainer>
          <StyledAboutContentTitle>Czym się zajmujemy?</StyledAboutContentTitle>
          <StyledContentList>
            <StyledListLitem>Budowa dachów od podstaw</StyledListLitem>
            <StyledListLitem>Remonty dachów</StyledListLitem>
            <StyledListLitem>Czyszczenie dachu</StyledListLitem>
            <StyledListLitem>Malowanie dachu</StyledListLitem>
            <StyledListLitem>Naprawa uszkodzonych elementów</StyledListLitem>
            <StyledListLitem>Czyszczenie elewacji</StyledListLitem>
            <StyledListLitem>Malowanie podbitki</StyledListLitem>
          </StyledContentList>
        </StyledContentContainer>
      </StyledAboutContainer>

      <StyledCountingBoxContainer>
        <CountingBox
          start={0}
          end={10}
          delay={1}
          description="Lat doświadczenia"
          darkMode={darkMode}
        />
        <CountingBox
          start={0}
          end={8}
          delay={2}
          description="Specjalistów na pokładzie"
          darkMode={darkMode}
        />
        <CountingBox
          start={0}
          end={1000}
          delay={3}
          description="Wykonanych projektów"
          darkMode={darkMode}
        />
        <CountingBox
          start={0}
          end={1000}
          delay={4}
          description="Zadowolonych klientów"
          darkMode={darkMode}
        />
      </StyledCountingBoxContainer>
    </SectionLayout>
  </StyledSectionWrapper>
);

About.propTypes = {
  darkMode: PropTypes.bool,
};

About.defaultProps = {
  darkMode: false,
};

const StyledSectionWrapper = styled.div`
  margin-top: 550px;
  @media ${maxDeviceSize.tablet} {
    margin-top: 270px;
  }
`;

const StyledAboutContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 3rem;
  padding: 2rem;
`;

const StyledVideoContainer = styled.div`
  flex: 1 1 20rem;
`;

const StyledVideo = styled.video`
  width: 80%;
  -webkit-box-shadow: -12px 16px 0px -4px rgba(227, 31, 37, 1);
  -moz-box-shadow: -12px 16px 0px -4px rgba(227, 31, 37, 1);
  box-shadow: -12px 16px 0px -4px rgba(227, 31, 37, 1);
`;

const StyledContentContainer = styled.div`
  flex: 0.8 16rem;
`;

const StyledAboutContentTitle = styled.h3`
  font-size: 2.5rem;
  font-weight: 300;
`;

const StyledContentList = styled.ol`
  font-size: 1.5rem;
  font-weight: 200;

  @media ${maxDeviceSize.tablet} {
    margin-left: 1rem;
  }
`;

const StyledListLitem = styled.li`
  padding-left: 16px;
  margin-top: 24px;
  position: relative;
  font-size: 18px;
  line-height: 20px;

  &:before {
    content: '';
    display: block;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    border: 2px solid #ddd;
    position: absolute;
    top: -8px;
    left: -30px;
  }

  &:hover {
    transform: translateY(-2px);
  }
`;

const StyledCountingBoxContainer = styled.div`
  margin-top: 3rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minMax(16rem, 1fr));
  gap: 2rem;
  align-items: flex-end;
`;

export default About;
