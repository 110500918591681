import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from 'utils/colors';
import { maxDeviceSize } from 'utils/deviceSize';

export const IconBoxLink = ({ Icon, onClick, href, ariaLabel, darkMode, style }) => (
  <StyledIconContainer
    darkMode={darkMode}
    onClick={onClick}
    href={href}
    aria-label={ariaLabel}
    target="_blank"
  >
    <Icon style={style} />
  </StyledIconContainer>
);

IconBoxLink.propTypes = {
  Icon: PropTypes.element.isRequired,
  onClick: PropTypes.element.isRequired,
  href: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  darkMode: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.string),
};

IconBoxLink.defaultProps = {
  darkMode: false,
  style: {},
};

const StyledIconContainer = styled.a`
  height: 4rem;
  width: 4rem;
  line-height: 4.5rem;
  font-size: 2rem;
  background-color: ${({ darkMode }) => (darkMode ? colors.black : colors.lightBackground)};
  border: ${({ darkMode }) => (darkMode ? `1px solid ${colors.white}` : 'none ')};
  cursor: pointer;
  text-align: center;
  flex-direction: row;
  margin: 0.3rem;
  &:hover {
    background: ${colors.red};
    color: ${colors.white};
  }

  @media ${maxDeviceSize.tablet} {
    height: 3rem;
    width: 3rem;
    line-height: 3.5rem;
    font-size: 1.5rem;
  }
`;
