import React, { useState } from 'react';
import { GlobalStyle } from 'styles/GlobalStyles';
import Home from 'sections/Home';
import About from 'sections/About';
import Realizations from 'sections/Realizations';
import Opinions from 'sections/Opinions';
import Contact from 'sections/Contact';
import { Footer } from 'components/molecues/Footer/Footer';
import { maxDeviceSize } from 'utils/deviceSize';
import { styled, ThemeProvider } from 'styled-components';
import HeaderLayout from 'layouts/HeaderLayout';
import { Navbar } from 'components/molecues/Navbar/Navbar';
import { IconBox } from 'components/atoms/IconBox/IconBox';
import Logo from 'assets/images/logo.jpg';
import DarkModeLogo from 'assets/images/logo-black.png';
import { AiOutlineBars } from 'react-icons/ai';
import { BsMoonStarsFill, BsSunFill } from 'react-icons/bs';
import { SideNavbar } from 'components/molecues/SideNavbar/SideNavbar';
import { lightTheme, darkTheme } from 'styles/Theme';
import PageUnderConstuctionPage from 'pages/PageUnderConstuctionPage';

const App = () => {
  const [toggleNav, setToggleNav] = useState(false);
  const [darkMode, setDarkMode] = useState(true);

  const handleToggleNav = () => {
    const mainTitle = document.getElementById('main-title');

    if (mainTitle.style.display === '') {
      mainTitle.style.display = 'none';
    }

    if (mainTitle.style.display === 'block') {
      mainTitle.style.display = 'none';
    }

    setToggleNav(!toggleNav);
  };

  const handleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  if (process.env.REACT_APP_ENV === 'development') {
    return (
      <ThemeProvider theme={darkMode === true ? darkTheme : lightTheme}>
        <GlobalStyle />
        <PageUnderConstuctionPage />
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={darkMode === true ? darkTheme : lightTheme}>
      <GlobalStyle />
      <HeaderLayout>
        <StyledLogo src={darkMode === true ? DarkModeLogo : Logo} alt="logo" />
        <Navbar />
        <StyledIconsWrapper>
          <IconBox
            id="sidebar-button"
            type="button"
            onClick={handleToggleNav}
            aria-label="Otwórz panel nawigacyjny"
            Icon={AiOutlineBars}
            darkMode={darkMode}
          />
          <IconBox
            id="darkmode-button"
            type="button"
            onClick={handleDarkMode}
            aria-label="Otwórz tryb nocny"
            Icon={darkMode === true ? BsSunFill : BsMoonStarsFill}
            darkMode={darkMode}
          />
        </StyledIconsWrapper>
        {toggleNav && <SideNavbar darkMode={darkMode} />}
      </HeaderLayout>
      <Home />
      <About theme={darkMode} darkMode={darkMode} />
      <Opinions darkMode={darkMode} />
      <Realizations darkMode={darkMode} />
      <Contact darkMode={darkMode} />
      <Footer />
    </ThemeProvider>
  );
};

const StyledLogo = styled.img`
  width: 15%;

  @media ${maxDeviceSize.phone} {
    width: 40%;
  }
`;

const StyledIconsWrapper = styled.div`
  display: flex;
`;

export default App;
