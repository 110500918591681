import React from 'react';
import { styled } from 'styled-components';
import { colors } from 'utils/colors';
import { IconBox } from 'components/atoms/IconBox/IconBox';
import { AiOutlineClose, AiFillPhone } from 'react-icons/ai';
import { FaSquareFacebook } from 'react-icons/fa6';
import { PiInstagramLogoFill } from 'react-icons/pi';
import { GrMail } from 'react-icons/gr';
import { maxDeviceSize } from 'utils/deviceSize';
import { IconBoxLink } from 'components/atoms/IconBoxLink/IconBoxLink';
import PropTypes from 'prop-types';
import { sideBar } from 'styles/Styles';
import { darkTheme } from 'styles/Theme';

export const SideNavbar = ({ darkMode }) => {
  const closeSideNavbar = () => {
    const sideBarButton = document.querySelector('#sidebar-button');
    if (sideBarButton !== null) {
      sideBarButton.click();
    }
    const mainTitle = document.getElementById('main-title');
    mainTitle.style.transition = '0.5 ease-in-out';
    mainTitle.style.display = 'block';
  };

  return (
    <StyledLeftNavbarContainer darkMode={darkMode}>
      <StyledNavbarListContainer>
        <StyledCloseNavbarContainer>
          <IconBox
            Icon={AiOutlineClose}
            onClick={closeSideNavbar}
            id="close-sidenabar-button"
            darkMode={darkMode}
          />
        </StyledCloseNavbarContainer>
        <StyledNavbarContainer>
          <IconBoxLink
            Icon={AiFillPhone}
            href={process.env.REACT_APP_NUMBER_LINK}
            darkMode={darkMode}
          />
          <StyledNavbarDescriptionContainer>
            Telefon:
            <StyledPhone href={process.env.REACT_APP_NUMBER_LINK}>
              {process.env.REACT_APP_NUMBER}
            </StyledPhone>
          </StyledNavbarDescriptionContainer>
        </StyledNavbarContainer>
        <StyledNavbarContainer>
          <IconBoxLink Icon={GrMail} href={process.env.REACT_APP_EMAIL_LINK} darkMode={darkMode} />
          <StyledNavbarDescriptionContainer>
            E-mail:
            <StyledPhone href={process.env.REACT_APP_EMAIL_LINK}>
              {process.env.REACT_APP_EMAIL}
            </StyledPhone>
          </StyledNavbarDescriptionContainer>
        </StyledNavbarContainer>
        <StyledSocalContainer>
          <IconBoxLink
            Icon={FaSquareFacebook}
            href={process.env.REACT_APP_FACEBOOK_LINK}
            darkMode={darkMode}
          />
          <IconBoxLink
            Icon={PiInstagramLogoFill}
            href={process.env.REACT_APP_INSTAGRAM_LINK}
            darkMode={darkMode}
          />
        </StyledSocalContainer>
      </StyledNavbarListContainer>
    </StyledLeftNavbarContainer>
  );
};

SideNavbar.propTypes = {
  darkMode: PropTypes.bool.isRequired,
};

const StyledNavbarListContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledLeftNavbarContainer = styled.div`
  @keyframes slideX {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  position: fixed;
  right: 0px;
  top: 0px;
  width: 30%;
  height: 100%;
  z-index: 99999;
  border-right-width: 1px;
  border-right-color: rgb(243 244 246);
  animation: slideX 0.3s ease-in-out;
  background-color: ${({ darkMode }) => (darkMode ? darkTheme.background : colors.white)};
  box-shadow: ${({ darkMode }) => (darkMode ? sideBar.dark : sideBar.light)};
  @media ${maxDeviceSize.tablet} {
    width: 60%;
  }
`;

const StyledCloseNavbarContainer = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  margin-top: 1rem;
  margin-left: 1rem;
`;

const StyledNavbarContainer = styled.div`
  margin-top: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const StyledNavbarDescriptionContainer = styled.div`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledPhone = styled.a`
  &:hover {
    color: ${colors.red};
  }
`;

const StyledSocalContainer = styled.div`
  margin-top: 6rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
`;
