import SectionTitle from 'components/atoms/SectionTitle/SectionTitle';
import SectionLayout from 'layouts/SectionLayout';
import React from 'react';
import MapImage from 'assets/images/mapa.png';
import { styled } from 'styled-components';
import { colors } from 'utils/colors';
import { maxDeviceSize } from 'utils/deviceSize';
import PropTypes from 'prop-types';

const Contact = ({ darkMode }) => (
  <SectionLayout name="contact" secondary="true">
    <SectionTitle title="Kontakt" secondary />
    <StyledContactWrapper darkMode={darkMode}>
      <div>
        <StyledContactInfo>Naciśnij na mapę, aby nawigować</StyledContactInfo>
        <StyledMapImageContainer
          href={process.env.REACT_APP_GOOGLE_MAP_LINK}
          target="_blank"
          aria-label="Link do mapy google"
        >
          <StyledMapImage src={MapImage} alt="Mapa google" />
        </StyledMapImageContainer>
      </div>
      <StyledContactBox>
        <StyledContactTitle>Adres:</StyledContactTitle>
        <StyledContactInfo>Bociania 7, 64-930 Dolaszewo</StyledContactInfo>
      </StyledContactBox>
      <StyledContactBox>
        <StyledContactTitle>Godziny otwarcia:</StyledContactTitle>
        <StyledContactInfo>poniedziałek 08:00–16:00 </StyledContactInfo>
        <StyledContactInfo>wtorek 08:00–16:00</StyledContactInfo>
        <StyledContactInfo>środa 08:00–16:00</StyledContactInfo>
        <StyledContactInfo>czwartek 08:00–16:00</StyledContactInfo>
        <StyledContactInfo>piątek 08:00–16:00</StyledContactInfo>
        <StyledContactInfo>sobota 08:00–16:00</StyledContactInfo>
        <StyledContactInfo>niedziela 08:00–16:00</StyledContactInfo>
      </StyledContactBox>
      <StyledContactBox>
        <StyledContactTitle>Telefon: </StyledContactTitle>
        <StyledPhone href={process.env.REACT_APP_NUMBER_LINK}>
          {process.env.REACT_APP_NUMBER}
        </StyledPhone>
      </StyledContactBox>
    </StyledContactWrapper>
  </SectionLayout>
);

Contact.propTypes = {
  darkMode: PropTypes.bool,
};

Contact.defaultProps = {
  darkMode: false,
};

const StyledMapImageContainer = styled.a``;

const StyledMapImage = styled.img`
  width: 500px;
  height: 400px;
  border: 1px solid ${colors.lightBackground};
  padding: 0.5rem;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
    transition: 0.5 ease-in-out;
  }

  @media ${maxDeviceSize.tablet} {
    width: 300px;
    height: 200px;
  }
`;

const StyledContactWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  align-items: center;
  color: ${colors.black};
  @media ${maxDeviceSize.tablet} {
    grid-template-columns: 1fr;
    padding: 0px 2rem;
  }
`;

const StyledContactTitle = styled.p`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: ${colors.black};
`;

const StyledContactBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${colors.black};
`;

const StyledPhone = styled.a`
  color: ${colors.black};
  &:hover {
    color: ${colors.red};
  }
`;

const StyledContactInfo = styled.p`
  color: ${colors.black};
`;

export default Contact;
