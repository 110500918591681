import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    * {
        font-family: 'Poppins', sans-serif;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        outline: none;
        border: none;
        text-decoration: none;
        transition: all .2s linear;
        color: ${({ theme }) => theme.text};
    }
    html {
        position: relative;
        min-height: 100%;
        overflow-x: hidden;
    }
    body {
        width: 100vw;
        height: 100vh;
    }
`;
