import React, { useState, useEffect } from 'react';
import { styled } from 'styled-components';
import PropTypes from 'prop-types';
import { Blurhash } from 'react-blurhash';

const BlurImage = ({ src, alt, hash, width, height }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImageLoaded(true);
    };
    img.src = src;
  }, [src]);

  return (
    <>
      {!imageLoaded && (
        <Blurhash
          hash={hash}
          width={Number(width)}
          height={Number(height)}
          resolutionX={32}
          resolutionY={32}
          punch={1}
        />
      )}
      {imageLoaded && (
        <StyledImage src={src} style={{ width: '100%', display: 'block' }} alt={alt} />
      )}
    </>
  );
};

BlurImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  hash: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
};

BlurImage.defaultProps = {
  width: '400',
  height: '300',
};

const StyledImage = styled.img`
  width: 100%;
  display: block;
`;

export default BlurImage;
