import SectionTitle from 'components/atoms/SectionTitle/SectionTitle';
import SectionLayout from 'layouts/SectionLayout';
import React, { useState } from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import realizationDataProvider from 'services/RealizationProvider';
import { colors } from 'utils/colors';
import { styled } from 'styled-components';
import { AiOutlineArrowLeft, AiOutlineArrowRight, AiOutlineClose } from 'react-icons/ai';
import { IconBox } from 'components/atoms/IconBox/IconBox';
import BlurImage from 'components/atoms/BlurImage/BlurImage';
import PropTypes from 'prop-types';

const Realizations = ({ darkMode }) => {
  const [fullScreenRealization, setFullScreenRealization] = useState({
    src: '',
    desc: '',
    index: 0,
  });
  const realizations = realizationDataProvider();

  const viewFullScreenRealization = (src, desc, index) => {
    setFullScreenRealization({ src, desc, index });
  };

  const realizationAction = (action) => {
    let { index } = fullScreenRealization;

    if (action === 'next-realization') {
      if (index + 1 === realizations.length) {
        index = 0;
      }
      setFullScreenRealization({
        src: realizations[index + 1].imageSrc,
        desc: realizations[index + 1].description,
        index: index + 1,
      });
    }
    if (action === 'previous-realization') {
      if (index === 0) {
        index = 9;
      }
      setFullScreenRealization({
        src: realizations[index - 1].imageSrc,
        desc: realizations[index - 1].description,
        index: index - 1,
      });
    }
    if (action === 'close-fullscreen') {
      setFullScreenRealization({
        src: '',
        desc: '',
        index: 0,
      });
    }
  };

  return (
    <>
      {fullScreenRealization.src && (
        <StyledFullScreenRealizationContainer>
          <StyledCloseFullScreenContainer>
            <IconBox
              Icon={AiOutlineClose}
              onClick={() => realizationAction('close-fullscreen')}
              id="close-preview-button"
              darkMode={darkMode}
            />
          </StyledCloseFullScreenContainer>
          <IconBox
            Icon={AiOutlineArrowLeft}
            onClick={() => realizationAction('previous-realization')}
            id="next-slide-button"
            darkMode={darkMode}
          />
          <StyledFullScreenRealizationImage
            src={fullScreenRealization.src}
            alt={fullScreenRealization.desc}
          />
          <IconBox
            Icon={AiOutlineArrowRight}
            onClick={() => realizationAction('next-realization')}
            id="previous-slide-button"
            darkMode={darkMode}
          />
        </StyledFullScreenRealizationContainer>
      )}
      <SectionLayout name="realizations">
        <SectionTitle title="Realizacje" />
        <ResponsiveMasonry
          columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
          style={{ padding: '10px' }}
        >
          <Masonry gutter="20px">
            {realizations.map((realization, key) => (
              <StyledImageButtonWrapper
                type="button"
                /* prettier-ignore */
                /* eslint-disable-next-line */
                onClick={() => viewFullScreenRealization(realization.imageSrc, realization.description, key)}
                key={realization.description}
              >
                <BlurImage
                  src={realization.imageSrc}
                  alt={realization.description}
                  hash={realization.hash}
                />
              </StyledImageButtonWrapper>
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </SectionLayout>
    </>
  );
};

Realizations.propTypes = {
  darkMode: PropTypes.bool,
};

Realizations.defaultProps = {
  darkMode: false,
};

const StyledCloseFullScreenContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const StyledFullScreenRealizationContainer = styled.div`
  width: 100%;
  height: 100%;
  background: ${colors.black};
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 99999;
`;

const StyledFullScreenRealizationImage = styled.img`
  width: auto;
  max-width: 90%;
  max-height: 90%;
`;

const StyledImageButtonWrapper = styled.button`
  cursor: pointer;
  &:hover {
    opacity: 0.8;
    transition: 0.5 ease-in-out;
  }
`;

export default Realizations;
