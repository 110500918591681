import styled from 'styled-components';
import { colors } from 'utils/colors';
// import { colors } from 'utils/colors';
import { maxDeviceSize } from 'utils/deviceSize';

const SectionLayout = styled.section`
  position: relative;
  width: 100%;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4rem calc((100vw - 1500px) / 2);
  background: ${({ theme, secondary }) => (secondary ? colors.lightBackground : theme.background)};
  @media ${maxDeviceSize.tablet} {
    flex-direction: column;
    padding: 2rem calc((180vw - 1500px) / 2);
  }
`;

export default SectionLayout;
