import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll';
import { colors } from 'utils/colors';
import { maxDeviceSize } from 'utils/deviceSize';

export const Navbar = () => (
  <StyledNavbar>
    <StyledNavbarItem href="#" to="about" spy smooth offset={50} duration={500} isDynamic>
      O nas
    </StyledNavbarItem>
    <StyledNavbarItem href="#" to="realizations" spy smooth offset={50} duration={500} isDynamic>
      Realizacje
    </StyledNavbarItem>
    <StyledNavbarItem href="#" to="opinions" spy smooth offset={50} duration={500} isDynamic>
      Opinie
    </StyledNavbarItem>
    <StyledNavbarItem href="#" to="contact" spy smooth offset={50} duration={500} isDynamic>
      Kontakt
    </StyledNavbarItem>
  </StyledNavbar>
);

const StyledNavbar = styled.nav`
  font-size: 1.2rem;
  color: ${colors.black};
  text-transform: capitalize;
`;

const StyledNavbarItem = styled(Link)`
  margin: 0 1rem;

  @media ${maxDeviceSize.tablet} {
    display: none;
  }

  &:hover {
    color: ${colors.red};
  }
`;
