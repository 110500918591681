export const lightTheme = {
  text: '#000',
  background: '#fff',
  backgroundSecondary: '#eee',
};
export const darkTheme = {
  text: '#FFF',
  background: '#000',
  backgroundSecondary: '#eee',
};
