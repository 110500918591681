import React from 'react';
import { styled } from 'styled-components';
import { colors } from 'utils/colors';
import Logo from 'assets/images/logo-vector.png';
import { maxDeviceSize } from 'utils/deviceSize';
import { FaSquareFacebook } from 'react-icons/fa6';
import { PiInstagramLogoFill } from 'react-icons/pi';
import { IconBoxLink } from 'components/atoms/IconBoxLink/IconBoxLink';

const PageUnderConstuctionPage = () => (
  <StyledPageUnderConstuctionContainer>
    <StyledLogo src={Logo} alt="logo" />
    <StyledDescriptionContainer>
      <StyledTitle>Strona w budowie</StyledTitle>
      <StyledLightDescription>Przygotowujemy dla Ciebie coś wyjątkowego!</StyledLightDescription>
      <StyledSocalContainer>
        <IconBoxLink
          Icon={FaSquareFacebook}
          href={process.env.REACT_APP_FACEBOOK_LINK}
          darkMode
          style={{ color: colors.white }}
        />
        <IconBoxLink
          Icon={PiInstagramLogoFill}
          href={process.env.REACT_APP_INSTAGRAM_LINK}
          darkMode
          style={{ color: colors.white }}
        />
      </StyledSocalContainer>
    </StyledDescriptionContainer>
  </StyledPageUnderConstuctionContainer>
);

const StyledPageUnderConstuctionContainer = styled.div`
  background: radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%);
  width: 100vw;
  height: 100vh;
`;

const StyledLogo = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 30%;

  @media ${maxDeviceSize.phone} {
    width: 40%;
  }
`;

const StyledDescriptionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const StyledLightDescription = styled.p`
  color: ${colors.white};
  text-align: center;
  margin-top: 2rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  font-size: 1.2rem;

  @media ${maxDeviceSize.phone} {
    font-size: 0.8rem;
  }
`;

const StyledTitle = styled.h1`
  color: ${colors.white};
  text-align: center;
  margin-top: 6rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;

  letter-spacing: 0.1rem;
  font-size: 4.5rem;

  @media ${maxDeviceSize.phone} {
    font-size: 2.5rem;
  }
`;

const StyledSocalContainer = styled.div`
  margin-top: 6rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
`;

export default PageUnderConstuctionPage;
