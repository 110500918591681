import React from 'react';
import { styled } from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from 'utils/colors';

const SectionTitle = ({ secondary, title, theme }) => (
  <StyledTitle secondary={secondary} theme={theme}>
    {title}
  </StyledTitle>
);

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  theme: PropTypes.bool.isRequired,
  secondary: PropTypes.bool,
};

SectionTitle.defaultProps = {
  secondary: false,
};

const StyledTitle = styled.h1`
  margin-bottom: 3rem;
  margin-left: 1rem;
  padding-left: 1rem;
  font-size: 1.5rem;
  color: ${({ secondary, theme }) => (secondary ? colors.black : theme.color)};
  text-transform: capitalize;
  border-left: 1rem solid ${colors.red};
`;

export default SectionTitle;
