import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from 'utils/colors';
import { maxDeviceSize } from 'utils/deviceSize';

export const IconBox = ({ Icon, onClick, id, noHover, darkMode }) => (
  <StyledIconContainer id={id} onClick={onClick} noHover={noHover} darkMode={darkMode}>
    <Icon />
  </StyledIconContainer>
);

IconBox.propTypes = {
  Icon: PropTypes.element.isRequired,
  onClick: PropTypes.element.isRequired,
  id: PropTypes.string.isRequired,
  noHover: PropTypes.string,
  darkMode: PropTypes.bool,
};

IconBox.defaultProps = {
  noHover: 'false',
  darkMode: false,
};

const StyledIconContainer = styled.div`
  height: 4rem;
  width: 4rem;
  line-height: 4.5rem;
  font-size: 2rem;
  cursor: pointer;
  text-align: center;
  flex-direction: row;
  margin: 0.3rem;
  background-color: ${({ darkMode }) => (darkMode ? colors.black : colors.lightBackground)};
  border: ${({ darkMode }) => (darkMode ? `1px solid ${colors.white}` : 'none ')};
  pointer-events: ${({ noHover }) => (noHover === 'true' ? 'none' : 'auto')};
  &:hover {
    background: ${colors.red};
    color: ${colors.white};
  }

  @media ${maxDeviceSize.tablet} {
    height: 3rem;
    width: 3rem;
    line-height: 3.5rem;
    font-size: 1.5rem;
  }
`;
