import { ReviewBox } from 'components/atoms/ReviewBox/ReviewBox';
import SectionTitle from 'components/atoms/SectionTitle/SectionTitle';
import SectionLayout from 'layouts/SectionLayout';
import React from 'react';
import reviewProvider from 'services/ReviewProvider';
import { styled } from 'styled-components';
import { maxDeviceSize } from 'utils/deviceSize';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import PropTypes from 'prop-types';

const Opinions = ({ darkMode }) => {
  const reviews = reviewProvider();
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <SectionLayout secondary="true" name="opinions">
      <SectionTitle title="Opinie" secondary />
      <StyledReviewsWrapper responsive={responsive}>
        {reviews.map((review) => (
          <ReviewBox
            key={review.author}
            author={review.author}
            stars={review.stars}
            review={review.review}
            link={review.link}
            darkMode={darkMode}
          />
        ))}
      </StyledReviewsWrapper>
    </SectionLayout>
  );
};

Opinions.propTypes = {
  darkMode: PropTypes.bool,
};

Opinions.defaultProps = {
  darkMode: false,
};

const StyledReviewsWrapper = styled(Carousel)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;

  @media ${maxDeviceSize.tablet} {
    grid-template-columns: 1fr;
    padding: 0px 2rem;
  }
`;
export default Opinions;
