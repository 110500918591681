import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from 'utils/colors';
import { AiFillStar } from 'react-icons/ai';
import { maxDeviceSize } from 'utils/deviceSize';
import { boxShadow } from 'styles/Styles';

export const ReviewBox = ({ author, stars, review, link, darkMode }) => {
  const generateStars = (starsNumber) => {
    const result = [];
    for (let index = 0; index < starsNumber; index++) {
      result.push(<AiFillStar fill="#FFFF00" />);
    }

    return result;
  };
  return (
    <StyledReviewBoxWrapper darkMode={darkMode}>
      <StyledAutor>{author}</StyledAutor>
      <StyledStars>
        {generateStars(stars).map(() => (
          <AiFillStar fill="#FFFF00" />
        ))}
      </StyledStars>
      <StyledReviewBoxDescription>{review}</StyledReviewBoxDescription>
      <StyledShowOpinontLink href={link} target="_blank">
        Zobacz całą opinię
      </StyledShowOpinontLink>
    </StyledReviewBoxWrapper>
  );
};
ReviewBox.propTypes = {
  author: PropTypes.string.isRequired,
  stars: PropTypes.string.isRequired,
  review: PropTypes.string.isRequired,
  link: PropTypes.string,
  darkMode: PropTypes.bool,
};

ReviewBox.defaultProps = {
  link: '',
  darkMode: false,
};

const StyledReviewBoxWrapper = styled.div`
  /* text-align: center; */
  background: ${colors.white};
  padding: 3rem 2rem;
  margin-left: 2rem;
  margin-right: 2rem;
  height: 400px;
  box-shadow: ${boxShadow.light};
  @media ${maxDeviceSize.tablet} {
    margin: 0;
    width: 230px;
  }
`;

const StyledReviewBoxDescription = styled.p`
  color: ${colors.lightColor};
  line-height: 1.5;
  padding-top: 0.5rem;
  padding: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
`;

const StyledAutor = styled.p`
  font-size: 1.5rem;
  color: ${colors.black};
`;

const StyledStars = styled.div`s`;

const StyledShowOpinontLink = styled.a`
  margin-top: 2rem;
  color: ${colors.black};
  &:hover {
    color: ${colors.red};
  }
`;
