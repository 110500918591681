const size = {
  tablet: '1050px',
  phone: '576px',
};

export const maxDeviceSize = {
  tablet: `(max-width: ${size.tablet})`,
  phone: `(max-width: ${size.phone})`,
};

export const minDeviceSize = {
  tablet: `(min-width: ${size.tablet})`,
  phone: `(min-width: ${size.phone})`,
};
